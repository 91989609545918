import { backgroundFlexibleProp, backgroundTypeProp, mediaQueryProp } from 'utils/proptypes/modules/mediaProps';
import { headingSizeProp, headingTagProp, wysiwygProp } from 'utils/proptypes/modules/textProps';

import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundSwitch from 'components/background/BackgroundSwitch';
import ButtonsList from 'components/buttons/ButtonsList';
import Heading from 'components/text/Heading';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { buttonsProp } from 'utils/proptypes/modules/buttonsProps';
import styled from 'libs/styled';

const Div = styled('div')``;

/**
 *  contentBlock used to display heading, text and buttons on a background.
 *
 * @param {object} background - Background object.
 * @param {string} backgroundType - Type of background if not supplied inside the background object.
 * @param {string} button - Main button, used as link.
 * @param {string} heading - Heading as a string.
 * @param {string} headingSize - The size of heading passed as props to Heading.
 * @param {string} headingTag - The html-tag used by heading; h1, h2, h3 etc.
 * @param {array} mediaQuery - Array of media-queries used by background image.
 * @param {string} tag - Small tag displayed above heading.
 * @param {array} text - Text as a wysiwyg-array.
 * @param {bool} useBackgroundBleed - Should the background cover the width of the viewport.
 * @param {*} rest - Props to pass to FlexibleWrapper.
 */

/* 
    Note: The classes .module-wrapper, .module-heading, .module-body and .module-footer 
    is neccesary for blocks that should be available inside the TwoColumnsLayout.
    These classes will make sure that the SpecialTwoColumnsLayout algins the .module-body
    regarding of .module-heading and .module-footer.

    <div className="module-wrapper">
        <div className="module-heading">
            Content display above main content
        </div>
        <div className="module-body">
            Main Content
        </div>
        <div className="module-footer">
            Content display below main content
        </div>
    </div>

    This will allow the SpecialTwoColumnsLayout to achive a layout similar to below:
     _____ 
    |_____|  _____
    |     | |     |
    |_____| |_____|
            |_____|
*/

const CallToAction = ({
    background,
    backgroundRatio,
    backgroundType,
    basic,
    buttons,
    heading,
    headingSize,
    headingTag,
    isColumn,
    mediaQuery,
    tag,
    text,
}) => (
    <div className="module-wrapper">
        <MaxWidthWrapper
            includeContentMargins
            className="module-heading"
            display="flex"
            flexDirection="column"
            px={isColumn ? 0 : null}
        >
            {heading && (
                <Heading as={headingTag} m={['0 0 32px', null, '0 0 56px']} size={headingSize}>
                    {heading}
                </Heading>
            )}
            <Div
                alignItems={['flex-start', null, 'center']}
                display="flex"
                justifyContent="space-between"
                mb={['12px', null, '24px']}
                width="100%"
                flexDirection={['column', null, 'row']}
            >
                {tag && <Paragraph>{tag}</Paragraph>}
                {basic && <ButtonsList m={['16px -8px -8px', null, '-8px']} buttons={buttons} />}
            </Div>
        </MaxWidthWrapper>
        <div className="module-body">
            <AspectWrapper ratio={backgroundRatio}>
                <BackgroundSwitch type={backgroundType} background={background} mediaQuery={mediaQuery} />
            </AspectWrapper>
        </div>
        <MaxWidthWrapper includeContentMargins className="module-footer" px={isColumn ? 0 : null}>
            <Div mx={[null, null, '-6px', '-12px']}>
                <Div
                    width={isColumn ? '100%' : ['100%', null, '66.66%', '50%']}
                    ml="auto"
                    px={[null, null, '6px', '12px']}
                >
                    {text && (
                        <Wysiwyg
                            data={text}
                            textComponent={props => <Paragraph size="xl" mt="24px" {...props} />}
                            tagComponents={{ url: TextLinkWysisyg }}
                        />
                    )}
                    {!basic && <ButtonsList pt="24px" buttons={buttons} />}
                </Div>
            </Div>
        </MaxWidthWrapper>
    </div>
);

CallToAction.propTypes = {
    background: backgroundFlexibleProp,
    backgroundRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    backgroundType: backgroundTypeProp,
    basic: PropTypes.bool,
    buttons: buttonsProp,
    heading: PropTypes.string,
    headingSize: headingSizeProp,
    headingTag: headingTagProp,
    isColumn: PropTypes.bool,
    mediaQuery: mediaQueryProp,
    tag: PropTypes.string,
    text: wysiwygProp,
};

export default CallToAction;
