import CallToActionBasicBlock from 'libs/wordpress/content/cmsBlocks/CallToActionBasicBlock';
import React from 'react';
import moduleMargins from 'config/theme/moduleMargins';
import styled from 'libs/styled';

const Section = styled('section')``;

const CallToActionBasicModule = ({ ...rest }) => (
    <Section mt={moduleMargins.md}>
        <CallToActionBasicBlock {...rest} />
    </Section>
);

export default CallToActionBasicModule;
