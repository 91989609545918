/* eslint-disable camelcase */
import CallToAction from 'components/contentBlocks/CallToAction';
import PropTypes from 'prop-types';
import React from 'react';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import { mediaQueries } from 'config/theme/breakpoints';
import transformButton from 'libs/wordpress/utils/transformButton';
import transformFlexibleBackground from 'libs/wordpress/utils/transformFlexibleBackground';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

const CallToActionBasicTransformer = ({ content = {}, isColumn = false }) => {
    const { background_media_flexible: backgroundFlexible, button, heading, tag } = content;

    // Transfrom background data
    const isAboveSm = useAboveBreakpoint('sm');
    const transformedBackground = transformFlexibleBackground(backgroundFlexible);
    const mobileBackground = transformedBackground.mobile;
    const desktopBackground = transformedBackground.desktop;

    return (
        <CallToAction
            basic
            background={isAboveSm ? desktopBackground : mobileBackground}
            backgroundRatio={isColumn ? 3 / 4 : [3 / 4, 16 / 9]}
            backgroundType={isAboveSm ? desktopBackground.type : mobileBackground.type}
            buttons={button ? [transformButton(button, { theme: 'outlinedBlack' })] : null}
            heading={heading ? decodeHTMLEntities(heading.text) : null}
            headingSize="lg"
            headingTag={heading ? heading.type : null}
            mediaQuery={mediaQueries.fullWidth}
            tag={decodeHTMLEntities(tag)}
            useBackgroundBleed={!isColumn}
        />
    );
};

CallToActionBasicTransformer.propTypes = {
    content: PropTypes.shape({
        background_media_flexible: PropTypes.exact({
            mobile: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object])),
            desktop: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object])),
        }).isRequired,
        button: PropTypes.object,
        heading: PropTypes.object,
        tag: PropTypes.string,
        paragraph: wysiwygProp,
    }),
    isColumn: PropTypes.bool,
};

export default CallToActionBasicTransformer;
